import { visitsBySourceUpdate } from "../../views/analytics/visitsBySource";
import { chartsUpdate } from "../../views/charts/charts";
import { leadsByIndustryUpdate } from "../../views/crm/leadsByIndustry";
import { leadsSourcesUpdate } from "../../views/crm/leadsSources";
import { campaignSalesUpdate } from "../../views/email-marketing/campaignSales";

const TOGGLE_EL = document.getElementById("color-mode-toggle");

// Some of the charts need to updated on color mode change
const updateWidgets = () => {
	leadsByIndustryUpdate();
	leadsSourcesUpdate();
	visitsBySourceUpdate();
	campaignSalesUpdate();
	chartsUpdate();
};

export const colorMode = () => {
	if (TOGGLE_EL) {
		let color = localStorage.getItem("color-mode") || "light";

		// Set color mode on toggle click
		TOGGLE_EL.addEventListener("click", () => {
			color === "dark" ? (color = "light") : (color = "dark");
			localStorage.setItem("color-mode", color);
			document.documentElement.setAttribute("data-bs-theme", color);

			updateWidgets();
		});
	}
};
